<div class="submenu">
	<div #submenuItemsContainer
	     class="submenu-items-container"
	     (mouseleave)="!uiParams.is_mobile_view && resetScroll()">
		<div *ngIf="uiParams.is_mobile_view"
		     (click)="this.backEvent.next($event); resetScroll()"
		     class="back-container">
			<div class="icon icon-keyboard_backspace"></div>
			<div class="item-label">Back to menu</div>
		</div>

		<a *ngFor="let item of submenuDefault"
		   [href]="item.url">
			<menu-submenu-item [submenuItem]="item">
			</menu-submenu-item>
		</a>

		<div *ngIf="!!submenuAdvanced.length"
		     class="advanced-accordion"
		     [attr.data-accordion-open]="accordion_open">
			<div class="menu-item toggle"
			     (click)="toggleAccordion()">
				<div class="inner">
					<div class="item-label" tt>Advanced</div>
					<div [ngClass]="'icon-expand_' + (accordion_open ? 'less' : 'more')">
					</div>
				</div>
			</div>
			<div class="accordion-items">
				<a *ngFor="let item of submenuAdvanced"
				   [href]="item.url">
					<menu-submenu-item [submenuItem]="item">
					</menu-submenu-item>
				</a>
			</div>
		</div>
	</div><!-- /.submenu-items-container -->
</div>
