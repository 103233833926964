import { Component, Input, OnChanges } from '@angular/core';
// Interfaces
import { MenuGroupItem } from '../interfaces';
// Services
import { UiParamsService } from '../services/ui-params.service';

// Helpers

@Component({
	selector    : 'menu-header',
	templateUrl : './header.component.html',
	styleUrls   : ['./header.component.scss']
})
export class HeaderComponent implements OnChanges {

	@Input() public headerStructure : MenuGroupItem[];
	@Input() public mainStructure : MenuGroupItem[];

	public setup_name : string;

	constructor(public uiParams : UiParamsService)
	{
	}

	ngOnChanges()
	{
		const setup = this.headerStructure.find(group => group.item_name === 'setup');
		if (setup) this.setup_name = setup.name;
	}
}
