import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Services
import { UiParamsService } from './services/ui-params.service';
// Components
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { HeaderSubmenusComponent } from './header-submenus/header-submenus.component';
import { HeaderSubmenuComponent } from './header-submenu/header-submenu.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { SearchComponent } from './search/search.component';
import { OverlayComponent } from './overlay/overlay.component';
import { GroupWrapComponent } from './group-wrap/group-wrap.component';
import { GroupComponent } from './group/group.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { SubmenuItemComponent } from './submenu-item/submenu-item.component';
import { SpotlightComponent } from './spotlight/spotlight.component';
import {BaseImportModule} from "../../../../src/app/base-import.module";
import { FormsModule } from '@angular/forms';
import { TooltipBubble } from './tooltip-bubble/tooltip-bubble';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/*
* NavigationComponent, Root for menu 2.0
* > HeaderComponent, Root for header
* > > HeaderLogoComponent
* > > HeaderSubmenusComponent
* > > > HeaderSubmenuComponent
* > SidebarComponent, Root for sidebar
* > > HamburgerComponent
* > > SearchComponent
* > > OverlayComponent
* > > GroupWrapComponent // ngFor*
* > > > GroupComponent
* > > > > SubmenuComponent
* > > > > > SubmenuItemComponent // ngFor*
* */

@NgModule({
	imports : [
		CommonModule,
		BaseImportModule,
		FormsModule,
		NgbModule
	],
	declarations : [
		NavigationComponent,
		HeaderComponent,
		HeaderLogoComponent,
		HeaderSubmenusComponent,
		HeaderSubmenuComponent,
		SidebarComponent,
		HamburgerComponent,
		SearchComponent,
		OverlayComponent,
		GroupWrapComponent,
		GroupComponent,
		SubmenuComponent,
		SubmenuItemComponent,
		SpotlightComponent,
		TooltipBubble
	],
	providers    : [
		UiParamsService
	],
	exports      : [
		SidebarComponent
	]
})
export class MenuModule {
}
