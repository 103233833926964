import { Component, Input } from '@angular/core';

@Component({
	selector    : 'menu-hamburger',
	templateUrl : './hamburger.component.html',
	styleUrls   : ['./hamburger.component.scss']
})
export class HamburgerComponent {

	@Input() public menu_hover_open : boolean;
	@Input() public menu_press_open : boolean;

}
