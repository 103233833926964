import { Component, Input, OnInit } from '@angular/core';
// Interfaces
import { MenuGroupItem, MinimalMenuItem } from '../interfaces';

@Component({
	selector    : 'menu-group-wrap',
	templateUrl : './group-wrap.component.html',
	styleUrls   : ['./group-wrap.component.scss']
})
export class GroupWrapComponent implements OnInit {
	public has_submenu: boolean;

	private _item: MenuGroupItem | MinimalMenuItem;

	get item(): MenuGroupItem
	{
		return this._item as MenuGroupItem;
	}

	@Input() set item(value: MenuGroupItem | MinimalMenuItem)
	{
		this._item = value;
	}

	ngOnInit(): void
	{
		// Determine if we should wrap into anchor
		this.has_submenu = !!this._item.submenu;
	}
}
