import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { forEach } from 'lodash';

import { SubmenuItem } from '../interfaces';
import { UiParamsService } from '../services/ui-params.service';

@Component({
	selector    : 'menu-submenu',
	templateUrl : './submenu.component.html',
	styleUrls   : ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

	@Input() public submenu : SubmenuItem[];

	@Output() backEvent = new EventEmitter<Event>();

	@ViewChild('submenuItemsContainer', { static: false }) submenuItemsContainer : ElementRef;

	public submenuDefault : SubmenuItem[] = [];
	public submenuAdvanced : SubmenuItem[] = [];

	public accordion_open : boolean = false;


	constructor(public uiParams : UiParamsService)
	{
	}

	ngOnInit(): void
	{
		// Filter both with only one iteration
		forEach(this.submenu, item => {
				const submenu = !!item.advanced ? this.submenuAdvanced : this.submenuDefault;
				submenu.push(item);
			}
		);
	}

	public resetScroll(instant = false)
	{
		const element = this.submenuItemsContainer.nativeElement;
		const supportsSmooth = 'scrollBehavior' in document.documentElement.style;
		const needsScrolling = element.scrollTop !== 0;
		if (needsScrolling) {
			if (!instant && supportsSmooth) {
				element.scrollTo({
					top      : 0,
					left     : 0,
					behavior : 'smooth'
				});
			} else { // IE and Edge & instant
				element.scrollTop = 0;
			}
		}
	}

	public toggleAccordion()
	{
		this.accordion_open = !this.accordion_open;
	}
}
