<div class="actions-container">

	<div *ngIf="!!webShop" class="weblinks">
		<div class="weblinks-container" *ngIf="webLinks.length">

			<a class="webshop-container"
			   [href]="webLink.url"
			   target="_blank"
			   *ngFor="let webLink of webLinks" [class]="webLink.css_class">
				<span class="short" tt>{{ webLink.name }}</span>
			</a>

		</div>

		<a [href]="webShop.url" class="webshop-container">
			<span class="short" tt>{{ webShop.item_name }}</span>
		</a>

		<menu-header-submenu [heading]="language.label"
		                     [items]="language.submenu">
		</menu-header-submenu>
	</div>

  <div *ngIf="!!language"
       class="language-container"
       (mouseenter)="language.hover = true"
       (mouseleave)="language.hover = false"
       [attr.data-hover-active]="language.hover">
    <span class="short">{{ current_language_shortname }}</span>
    <div class="icon-public"></div>

    <menu-header-submenu [heading]="language.label"
                         [items]="language.submenu">
    </menu-header-submenu>
  </div>

  <div *ngIf="!!help"
       class="help-container"
       (mouseenter)="help.hover = true"
       (mouseleave)="help.hover = false"
       [attr.data-hover-active]="help.hover">
    <div class="icon-help"></div>
    <menu-header-submenu [heading]="help.label"
                         [items]="help.submenu">
    </menu-header-submenu>
  </div>

  <div *ngIf="!!account"
       class="account-container"
       (mouseenter)="account.hover = true"
       (mouseleave)="account.hover = false"
       [attr.data-hover-active]="account.hover">
    <div class="icon-account_circle"></div>
    <menu-header-submenu [heading]="userName"
                         [items]="account.submenu">
    </menu-header-submenu>
  </div>

  <div *ngIf="!!logoutLink"
       class="logout-container"
       [title]="logoutLink.label">
    <a [href]="logoutLink.url">
      <div class="icon-logout"></div>
    </a>
  </div>

</div>
