import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
	selector : '[focus]'
})
export class FocusDirective {
	@Input()
	focus: boolean;

	constructor(private element: ElementRef)
	{
	}

	protected ngOnChanges(): void
	{
		if (this.focus) {
			setTimeout(() => this.element.nativeElement.focus(), 0);
		}
	}
}
