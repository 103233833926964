import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, tap } from 'rxjs/operators';
import { CallService } from '../../../../../src/app/shared/services';
import { isNil } from 'lodash';

interface searchItem {
	name : string;
	url : string;
	type : string;
	meta : string[];
	date_search? : boolean;
}

interface menuItem {
	item_name : string;
	label : string;
	url : string;
	category? : string;
	submenu? : menuItem[];
	meta?: any[];
}

@Component({
	selector    : 'spotlight',
	templateUrl : './spotlight.component.html',
	styleUrls   : ['./spotlight.component.scss']
})
export class SpotlightComponent {
	@HostListener('window:keyup', ['$event'])
	keyEvent(event : KeyboardEvent)
	{
		this.handleKeyEvents(event);
	}

	@ViewChild('search_input') search_input : ElementRef;
	@ViewChild('search_result_global') search_result_global : ElementRef;
	@ViewChild('d') d : ElementRef;

	public search_opened$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public search_query = '';
	private search_query_changed : Subject<string> = new Subject<string>();
	public search_button_text = 'Ctrl + K';
	public arrow_key_location = 0;
	public placeholder_text = '';

	//Client search via menu items
	private menu_search_items : menuItem[] = [];
	public menu_search_translations : menuItem[] = [];
	public menu_search_items_filtered : menuItem[] = [];

	//Global search
	public loading = false;
	public global_search_items_filtered : menuItem[] = [];

	// Hotkeys
	private shiftClickTimer = null;
	private shiftClickCounter = 0;

	public date = {
		day   : null,
		month : null,
		year  : null
	};
	public show_date = false;

	public icons = {
		'address'               : 'pin',
		'printable_invoice'     : 'invoice',
		'token'                 : 'barcode',
		'total_price'           : 'euro',
		'creation_date'         : 'calendar_plus',
		'date'                  : 'calendar_today',
		'due_date'              : 'clock',
		'phone'                 : 'phone',
		'log'                   : 'log',
		'user'                  : 'user',
		'locker_state_locked'   : 'lock_closed',
		'locker_state_unlocked' : 'lock_open',
		'email'                 : 'email'
	};

	constructor(private call : CallService)
	{
	}

	ngOnInit()
	{
		if (navigator.platform === 'MacIntel' || navigator.platform === 'MacSilicone') this.search_button_text = '⌘ + K';
		setTimeout(() => {
			window.addEventListener('hotkey_search', (event : CustomEvent) => {
				this.search_input.nativeElement.focus();
			});
			this.menu_search_items = window['nexus_menu_items'];
			this.menu_search_translations = window['nexus_menu_items_translations'];
			this.placeholder_text = this.menu_search_translations['Search from everywhere'];
		}, 0);

		//We need to make z-index lower on other places, so we can see the search results
		this.search_opened$.subscribe((opened) => {
			if (opened) {
				document.body.classList.add('search-opened');
			} else {
				document.body.classList.remove('search-opened');
			}
		});
	}

	ngAfterViewInit()
	{
		// server-side search
		this.search_query_changed
		.pipe(
			filter(Boolean),
			debounceTime(400),
			tap(() => {
				this.globalSearch();
			})
		)
		.subscribe();
	}

	searchMenuItems()
	{
		this.arrow_key_location = 0;
		this.loading = true;
		if (!this.search_query.length) {
			this.menu_search_items_filtered = [];
			this.global_search_items_filtered = [];
			this.loading = false;
			return;
		}

		if (this.searchCanBeTriggered()) {
			this.search_query_changed.next(this.search_query);
		} else {
			this.global_search_items_filtered = [];
			this.loading = false;
		}

		//Filter menu search items by search query
		this.menu_search_items_filtered = this.menu_search_items.filter((item) => {
			return item.label.toString().toLowerCase().includes(this.search_query.toLowerCase());
		}).slice(0, 7);
	}

	searchKeyOperations(event : KeyboardEvent)
	{
		const search_length = this.menu_search_items_filtered.length + this.global_search_items_filtered.length;
		switch (event.key) {
			case 'ArrowUp':
				if (!this.search_query) return;

				event.preventDefault();
				event.stopPropagation();
				// We cant go to the negative value, so we go to the end of search results
				this.arrow_key_location = (this.arrow_key_location === 0) ? search_length - 1 : this.arrow_key_location - 1;
				this.scrollInsideSearch();
				break;
			case 'ArrowDown':
				if (!this.search_query) return;

				event.preventDefault();
				event.stopPropagation();
				this.arrow_key_location = (this.arrow_key_location === search_length - 1) ? 0 : this.arrow_key_location + 1;
				this.scrollInsideSearch();
				break;
			case 'Enter':
				if (!this.search_query) return;

				event.preventDefault();
				event.stopPropagation();
				this.openSearchItem();
				break;
			case 'Escape':
				this.closeSearch();
				break;
		}
	}

	isItemSelected(index : number)
	{
		return this.arrow_key_location === index;
	}

	openSearchItem()
	{
		if (this.arrow_key_location < this.menu_search_items_filtered.length) {
			window.location.href = this.getMenuItemUrl(this.menu_search_items_filtered[this.arrow_key_location]);
		} else {
			const index = this.arrow_key_location - this.menu_search_items_filtered.length;
			window.location.href = this.global_search_items_filtered[index].url;
		}
	}

	globalSearch()
	{
		this.loading = true;
		if (!this.searchCanBeTriggered()) {
			this.global_search_items_filtered = [];
			this.loading = false;
			return;
		}

		const call_params = [this.search_query];
		const date_value = this.date.day ? `${this.date?.month}/${this.date?.day}/${this.date?.year}` : '';
		call_params.push(date_value);

		this.call.make<searchItem[]>('common/searchall', call_params).pipe(
			finalize(() => {
				this.loading = false;
			})
		).subscribe(
			(res) => {
				this.global_search_items_filtered = res.map((item) => {
					if (!!item.date_search) this.show_date = true;
					return {
						item_name : item.name,
						label     : item.name,
						url       : item.url,
						category  : item.type,
						meta      : item.meta ?? []
					} as unknown as menuItem;
				});
			},
			(error) => {
				this.global_search_items_filtered = [];
			});
	}

	private handleKeyEvents(event : KeyboardEvent)
	{
		switch (event.key) {
			case 'Shift':
				const event_target = event.target as HTMLElement;
				if (event_target.tagName === 'INPUT' || event_target.tagName === 'TEXTAREA') return;

				if (this.shiftClickTimer) clearTimeout(this.shiftClickTimer);
				this.shiftClickCounter++;
				if (this.shiftClickCounter === 2) {
					this.search_input.nativeElement.focus();
				}

				this.shiftClickTimer = setTimeout(() => this.shiftClickCounter = 0, 300);
				break;
		}

		this.searchKeyOperations(event);
	}

	focusInput()
	{
		this.search_input.nativeElement.focus();
	}

	inputFocused()
	{
		this.search_opened$.next(true);
		this.placeholder_text = this.menu_search_translations['Start typing to search...'];
	}

	inputUnfocused()
	{
		if (!this.search_query) this.search_opened$.next(false);
		this.placeholder_text = this.menu_search_translations['Search from everywhere'];
	}

	closeSearch()
	{
		this.search_query = '';
		this.search_opened$.next(false);
		this.menu_search_items_filtered = [];
		this.global_search_items_filtered = [];
		this.search_input.nativeElement.blur();
	}

	private scrollInsideSearch()
	{
		setTimeout(() => {
			const search_result_global = this.search_result_global.nativeElement;
			const search_top = search_result_global.offsetTop;
			const search_item = search_result_global.querySelector('.search-result-item.highlighted');
			if (isNil(search_item)) return;

			const item_height = search_item.offsetHeight;
			const item_position = search_item.offsetTop;
			search_result_global.scrollTop = item_position - search_top - item_height;
		}, 0);
	}

	getMetaIcon(key : string)
	{
		return this.icons[key] ?? '';
	}

	isSimpleMetaValue(value : any)
	{
		return typeof value === 'string' || typeof value === 'number';
	}

	private searchCanBeTriggered()
	{
		return this.search_query.length >= 3 || /^\d+$/.test(this.search_query);
	}

	getMenuItemUrl(item : menuItem)
	{
		if (item.url) return item.url;
		if (item.submenu && item.submenu.length) return item.submenu[0].url;
		return null;
	}

	resetDate()
	{
		this.date = {
			day   : null,
			month : null,
			year  : null
		};
		this.globalSearch();
	}

	openDate()
	{
		// @ts-ignore - ngbDatepicker
		this.d.toggle();
	}
}
