import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SubmenuComponent } from '../submenu/submenu.component';
// Interfaces
import { MenuGroupItem, SubmenuItem } from '../interfaces';
// Helper
import { matchItemNameToIcon } from '../misc';
// Services
import { UiParamsService } from '../services/ui-params.service';

@Component({
	selector    : 'menu-group',
	templateUrl : './group.component.html',
	styleUrls   : ['./group.component.scss']
})
export class GroupComponent implements OnInit {

	@Input() public item : MenuGroupItem;

	@ViewChild(SubmenuComponent, { static: false }) submenuComponent : SubmenuComponent;

	public submenu : SubmenuItem[];
	public has_submenu : boolean;
	public icon : string;
	public hover_press : boolean = false;


	constructor(public uiParams : UiParamsService)
	{
	}

	ngOnInit(): void
	{
		// Set icon
		this.icon = matchItemNameToIcon(this.item.item_name);
		// Determine if submenu should be rendered
		this.has_submenu = !!this.item.submenu;
		// If item has url then pass it as parent to submenu
		const parentToSubmenu = {
			label  : this.item.label,
			url    : this.item.url,
			parent : true
		};

		if (this.has_submenu) {
			if (!!this.item.url) {
				this.submenu = [
					parentToSubmenu,
					...this.item.submenu
				];
			} else this.submenu = this.item.submenu;
		}
	}

	private resetSubmenuScroll() : void
	{
		if (this.has_submenu) this.submenuComponent.resetScroll();
	}

	public handleEnterClick(): void {
		this.hover_press = true;
		this.resetSubmenuScroll();
	}

	public handleLinkClick()
	{
		if (this.item.url) {
			window.location.href = this.item.url;
		} else if (this.has_submenu) {
			window.location.href = this.item.submenu[0].url;
		}
	}

}
