import { Component, Input, OnInit } from '@angular/core';
import { SubmenuItem } from '../interfaces';

@Component({
	selector    : 'menu-submenu-item',
	templateUrl : './submenu-item.component.html',
	styleUrls   : ['./submenu-item.component.scss']
})
export class SubmenuItemComponent implements OnInit {

	@Input() public submenuItem : SubmenuItem;

	public is_parent : boolean;

	ngOnInit(): void
	{
		this.is_parent = this.submenuItem.parent;
	}

}
