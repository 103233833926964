<div class="search-trigger" [class.searching]="search_opened$.getValue()">
	<svg fill="#000000" height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg"
	     xmlns:xlink="http://www.w3.org/1999/xlink"
	     viewBox="0 0 488.4 488.4" xml:space="preserve">
<g>
	<g>
		<path d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6
			s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2
			S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7
			S381.9,104.65,381.9,203.25z"/>
	</g>
</g>
</svg>
	<input type="text"
	       [(ngModel)]="search_query"
	       (ngModelChange)="searchMenuItems()"
	       class="form-control"
	       [class.has-date]="show_date && date.day"
	       (focus)="inputFocused()"
	       (blur)="inputUnfocused()"
	       #search_input
	       [placeholder]="placeholder_text"/>
	<button class="btn btn-outline-secondary" *ngIf="!search_opened$.getValue()" (click)="focusInput()">
		{{this.search_button_text}}
	</button>
	<div class="badge badge-calendar" *ngIf="show_date && date.day && search_opened$.getValue()" (click)="openDate()">
		{{date.day}}.{{date.month}}.{{date.year}}
		<i class="fa fa-times" (click)="resetDate();"></i>
	</div>

	<div class="search-result" *ngIf="search_opened$.getValue()">

		<div class="search-calendar" *ngIf="show_date">
			<input type="text"
			       class="hidden"
			       [(ngModel)]="date"
			       (ngModelChange)="globalSearch()"
			       ngbDatepicker
			       autocomplete="new-password"
			       #d="ngbDatepicker"/>
			<i class="fa fa-calendar" (click)="d.toggle();"></i>
		</div>

		<ng-container *ngIf="menu_search_items_filtered.length">
			<h6 class="search-result--source">
				<span tt>Menu items</span>
			</h6>
			<div class="not_found" *ngIf="search_query && !menu_search_items_filtered.length" tt>
				No results
			</div>
			<div class="search-result-client">
				<ng-container *ngFor="let item of menu_search_items_filtered; let index = index">
					<div class="search-result-item"
					     [class.highlighted]="isItemSelected(index)"
					     (mouseenter)="arrow_key_location = index"
					     *ngIf="getMenuItemUrl(item)">
						<a [href]="getMenuItemUrl(item)">
							<div class="search-result-item-title">{{item.label}}</div>
							<div class="search-result-item-description" *ngIf="item.category">{{item?.category}}</div>
						</a>
					</div>
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="global_search_items_filtered.length">
			<h6 class="search-result--source">
				<span tt>Global search</span>
				<i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
			</h6>
			<div class="search-result-global"
			     #search_result_global
			     [class.loading]="loading">
				<div class="search-result-item"
				     [class.highlighted]="isItemSelected(index + menu_search_items_filtered.length)"
				     (mouseenter)="arrow_key_location = index + menu_search_items_filtered.length"
				     *ngFor="let item of global_search_items_filtered; let index = index">
					<a [href]="item.url">
						<div class="search-result-item-title">{{item.label}}</div>
						<div class="search-result-item-description" *ngIf="item.category">{{item?.category}}</div>
					</a>
					<div class="search-result-item-meta" *ngIf="(item.meta | keyvalue)?.length">
						<ng-container *ngFor="let item_meta of item.meta | keyvalue; let index = index">
							<div class="meta__item"
							     *ngIf="item_meta.value"
							     [class.has_icon]="getMetaIcon(item_meta.key)"
							     [class.hint]="item_meta.value?.hint"
							     [class]="item_meta.key">

								<ng-container *ngIf="isSimpleMetaValue(item_meta.value)">
									<div [class]="'icon-' + getMetaIcon(item_meta.key)"></div>
									{{item_meta.value}}
								</ng-container>

								<ng-container *ngIf="item_meta.value.hint">
									<div [class]="'icon-' + getMetaIcon(item_meta.key)"></div>
									<div class="meta__item-hint">
										{{item_meta.value.hint}}
									</div>
									<div class="meta__item-hint-value">
										{{item_meta.value.value}}
									</div>
								</ng-container>

								<ng-container *ngIf="item_meta.value.url">
									<div [class]="'icon-' + getMetaIcon(item_meta.key)"></div>
									<a [href]="item_meta.value.url" target="_blank">
										{{item_meta.value.name}}
									</a>
								</ng-container>

							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="!loading && search_query.length && !global_search_items_filtered.length &&
				!menu_search_items_filtered.length">
			<div class="search-result--source"></div>
			<div class="not_found" tt>No results</div>
		</ng-container>

		<ng-container *ngIf="loading && !global_search_items_filtered.length && !menu_search_items_filtered.length">
			<h6 class="search-result--source">
				<span tt>Loading...</span>
				<i class="fa fa-spinner fa-spin"></i>
			</h6>
		</ng-container>

		<ng-container *ngIf="!search_query.length">
			<div class="search-result--source"></div>
			<div class="search-instructions">
				<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
					<path
						d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM105.8 229.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L216 328.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V314.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H158.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM160 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
				</svg>

				<div>
					<h5 tt>Search the whole system for:</h5>
					<ul>
						<li tt>customers and users (search by name, phone number or e-mail)</li>
						<li tt>invoices (by ID)</li>
						<li tt>receipts</li>
						<li tt>fare products</li>
						<li tt>lockers</li>
						<li tt>system functions (reports and other pages)</li>
						<li tt>and many others</li>
					</ul>
				</div>
			</div>
		</ng-container>

	</div>
</div>

<div class="search-backdrop" *ngIf="search_opened$.getValue()" (click)="closeSearch()"></div>
