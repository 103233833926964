// Label from nexus system matched to correct sass nexus_icons map keys
export const matchItemNameToIcon = (item_name : string) => {
	let icon_class_name;

	switch (item_name) {
		case 'home': {
			icon_class_name = 'home';
			break;
		}
		case 'products': {
			icon_class_name = 'category';
			break;
		}
		case 'manage_users': {
			icon_class_name = 'people';
			break;
		}
		case 'customers': {
			icon_class_name = 'people';
			break;
		}
		case 'access': {
			icon_class_name = 'key';
			break;
		}
		case 'access_monitor': {
			icon_class_name = 'key';
			break;
		}
		case 'settings': {
			icon_class_name = 'settings';
			break;
		}
		case 'reservations_': {
			icon_class_name = 'calendar_today';
			break;
		}
		case 'reservations': {
			icon_class_name = 'calendar_today';
			break;
		}
		case 'reports': {
			icon_class_name = 'list_alt';
			break;
		}
		case 'ta': {
			icon_class_name = 'watch_later';
			break;
		}
		case 'sales_and_payments': {
			icon_class_name = 'credit_card';
			break;
		}
		case 'system_settings': {
			icon_class_name = 'settings_input_component';
			break;
		}
		case 'administration': {
			icon_class_name = 'build';
			break;
		}
		case 'instructor_view': {
			icon_class_name = 'calendar_today';
			break;
		}
		case 'record_worktimes': {
			icon_class_name = 'schedule';
			break;
		}
		case 'locker_ui': {
			icon_class_name = 'lock';
			break;
		}
		case 'balance': {
			icon_class_name = 'hand_holding_dollar';
			break;
		}
		case 'nexus_deploy': {
			icon_class_name = 'nexus_deploy';
			break;
		}
		default: {
			icon_class_name = 'link';
		}
	}

	return `icon-${icon_class_name}`;
};

/*
* Assessment
* settings input
* admin => superviced_user
* web
* developer board
* */
