import { Injectable } from '@angular/core';

@Injectable()
export class UiParamsService {

	private _is_mobile_view : boolean = false;

	constructor()
	{
	}

	public get is_mobile_view() : boolean
	{
		return this._is_mobile_view;
	}

	public set is_mobile_view(value : boolean)
	{
		this._is_mobile_view = value;
	}
}
