import { Component, Input, OnInit } from '@angular/core';
import { MenuGroupItem } from '../interfaces';

@Component({
	selector    : 'menu-header-logo',
	templateUrl : './header-logo.component.html',
	styleUrls   : ['./header-logo.component.scss']
})
export class HeaderLogoComponent implements OnInit {

	@Input() public mainStructure : MenuGroupItem[];
	@Input() public headerStructure : MenuGroupItem[];

	public home : MenuGroupItem;
	public build_name : string;

	constructor()
	{
	}

	ngOnInit()
	{
		this.home = this.mainStructure.find(item => item.item_name === 'home');
	}

	ngOnChanges()
	{
		const setup = this.headerStructure.find(group => group.item_name === 'setup');
		if (setup) this.build_name = setup.build_name;
	}

}
