<div (mouseenter)="!uiParams.is_mobile_view && handleEnterClick() "
     (mouseleave)="!uiParams.is_mobile_view && (hover_press = false)"
     (click)="has_submenu && uiParams.is_mobile_view ? handleEnterClick() : ''"
     [class]="item.active ? 'active' : ''"
     class="menu-item cursor-pointer"
     [attr.data-hover-press-active]="hover_press">
	<div [ngClass]="icon"></div>
	<div class="inner" (click)="has_submenu && !uiParams.is_mobile_view ? handleLinkClick() : ''">
		<div class="item-label">{{ item.label }}</div>
		<div *ngIf="has_submenu"
		     class="icon-navigate_next">
		</div>
	</div>

	<menu-submenu *ngIf="has_submenu"
	              (backEvent)="hover_press = false; $event.stopPropagation()"
	              [submenu]="submenu">
	</menu-submenu>
</div>
