<a [href]="home?.url ? home.url : ''">
	<svg class="enkora-logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	     viewBox="0 0 425.1 71.1" enable-background="new 0 0 425.1 71.1" xml:space="preserve">
		<g>
			<path fill="#000000" d="M190.6,2c-6.8,0-14.2,3-19.5,8.2c-1.5,1.5-3,3-3.8,4.5c0-8.2,0-8.2,0-8.2c0-0.8-0.8-1.5-2.2-1.5c-0.8,0-1.5,0.8-1.5,1.5
				c0,48.8,0,48.8,0,48.8c0,0.8,0.8,1.5,1.5,1.5c1.5,0,2.2-0.8,2.2-1.5c0-30.8,0-30.8,0-30.8c0-3.8,1.5-8.2,6-12
				c4.5-4.5,11.2-7.5,17.2-6.8c9.8,0.8,13.5,6,13.5,18.8c0,30.8,0,30.8,0,30.8c0,0.8,0.8,1.5,2.2,1.5c0.8,0,1.5-0.8,1.5-1.5
				c0-30.8,0-30.8,0-30.8C207.9,15.5,205.6,3.5,190.6,2L190.6,2z"/>
			<path fill="#000000" d="M300.9,2.7c-8.2,0-15.8,4.5-20.2,11.2c-3,3.8-4.5,8.2-4.5,13.5c0,4.5,0,4.5,0,4.5c0,6,1.5,10.5,5.2,15
				c4.5,6,11.2,9.8,19.5,9.8c6,0,12-3,17.2-7.5c4.5-4.5,6.8-10.5,6.8-17.2c0-4.5,0-4.5,0-4.5c0-6-1.5-10.5-5.2-15
				C315.1,6.5,308.4,2.7,300.9,2.7L300.9,2.7z M321.9,32c0,6-2.2,11.2-6.8,15c-3.8,3.8-9,6-14.2,6c-6.8,0-12.8-3-17.2-8.2
				c-2.2-3.8-4.5-7.5-4.5-12.8c0-4.5,0-4.5,0-4.5c0-4.5,1.5-8.2,3.8-12c4.5-6,10.5-9,18-9c6,0,12,3,16.5,8.2c3,3.8,4.5,7.5,4.5,12.8
				V32z"/>
			<path fill="#000000" d="M368.4,5c-6.8-0.8-13.5,0.8-18,4.5c-1.5,1.5-3,3.8-4.5,5.2c0-8.2,0-8.2,0-8.2c0-0.8-0.8-1.5-1.5-1.5s-1.5,0.8-1.5,1.5
				c0,21,0,21,0,21c0,4.5,0,4.5,0,4.5c0,23.2,0,23.2,0,23.2c0,0.8,0.8,1.5,1.5,1.5s1.5-0.8,1.5-1.5c0-23.2,0-23.2,0-23.2
				c0-4.5,0-4.5,0-4.5c0-6,2.2-12,6.8-15c3.8-3.8,9-5.2,15-4.5c1.5,0.8,2.2,0,2.2-1.5C369.9,5.7,369.1,5,368.4,5L368.4,5z"/>
			<path fill="#000000" d="M144.1,29c0.8,0,1.5-0.8,1.5-1.5c0.8-7.5-1.5-13.5-5.2-18s-9.8-6.8-16.5-6.8c-13.5,0-24.8,11.2-24.8,24.8
				c0,4.5,0,4.5,0,4.5c0,13.5,11.2,24.8,24.8,24.8c3,0,13.5,0,21-6c0.8-0.8,0.8-1.5,0.8-2.2c-0.8-0.8-2.2-0.8-3,0
				c-6,4.5-15.8,4.5-18.8,4.5c-12,0-21-9-21-21c0-3,0-3,0-3H144.1z M123.9,6.5c6,0,10.5,1.5,14.3,5.2c3,3,4.5,8.2,4.5,13.5
				c-39.8,0-39.8,0-39.8,0C103.6,14.7,112.6,6.5,123.9,6.5L123.9,6.5z"/>
			<path fill="#000000" d="M398.4,2.7c-4.5,0-9,0-15.8,3c-0.8,0.8-0.8,1.5-0.8,2.2s1.5,1.5,2.2,0.8c6-2.2,9.8-2.2,14.2-2.2c9.8,0,17.2,6.8,18.8,17.2
				c-18.8,0-18.8,0-18.8,0c-16.5,0-21,9.8-21,15.8c0,10.5,6.8,17.2,18.8,17.2c9,0,16.5-5.2,21-12c0,10.5,0,10.5,0,10.5
				c0,0.8,0.8,1.5,1.5,1.5s1.5-0.8,1.5-1.5c0-23.2,0-23.2,0-23.2c0-4.5,0-4.5,0-4.5C420.1,13.2,411.1,2.7,398.4,2.7L398.4,2.7z
				 M396.1,53c-13.5,0-15.8-8.2-15.8-13.5c0-6,4.5-12.8,18-12.8c18.8,0,18.8,0,18.8,0s0,0,0,0.8c0,4.5,0,4.5,0,4.5
				C417.1,44,407.4,53,396.1,53L396.1,53z"/>
			<path fill="#000000" d="M264.9,5.7c-0.8-0.8-1.5-1.5-2.2-0.8c-33,21.8-33,21.8-33,21.8c0-20.2,0-20.2,0-20.2c0-0.8-0.8-1.5-1.5-1.5
				s-1.5,0.8-1.5,1.5c0,48.8,0,48.8,0,48.8c0,0.8,0.8,1.5,1.5,1.5s1.5-0.8,1.5-1.5c0-23.2,0-23.2,0-23.2c33,24.8,33,24.8,33,24.8h0.8
				c0.8,0,0.8,0,1.5,0c0.8-0.8,0-2.2-0.8-3c-32.2-24-32.2-24-32.2-24C264.1,8,264.1,8,264.1,8C264.9,7.2,265.6,6.5,264.9,5.7
				L264.9,5.7z"/>
			<path fill="#E95E0C" d="M64.6,5C54.1,5,54.1,5,54.1,5c0,51,0,51,0,51c10.5,0,10.5,0,10.5,0c3.8,0,6.8-3,6.8-7.5
				c0-36.8,0-36.8,0-36.8C71.4,8,68.4,5,64.6,5L64.6,5z"/>
			<path fill="#E95E0C" d="M24.1,5C10.6,5,10.6,5,10.6,5c-4.5,0-7.5,3-7.5,6.8c0,36.8,0,36.8,0,36.8c0,4.5,3,7.5,7.5,7.5
				c13.5,0,13.5,0,13.5,0V5z"/>
			<path fill="#EF952C" d="M54.1,56C39.9,68.7,39.9,68.7,39.9,68.7c-1.5,0.8-3,0-3-1.5c0-45.8,0-45.8,0-45.8c0-1.5,0.8-2.2,1.5-3
				C54.1,5,54.1,5,54.1,5V56z"/>
		</g>
	</svg>
	<div class="build_name" *ngIf="build_name">
		{{build_name}}
	</div>
</a>

<!-- Works with serve: ../resource/logo-enkora-black.svg depending on location -->
<!-- Does not work with assets/... as we cannot control the base href -->
<!-- Does not work in test.enkora with /resource/... so only option we see now is direct svg element -->
