<menu-group *ngIf="has_submenu; else anchor"
            [item]="item">
</menu-group>

<ng-template #anchor>
	<a [href]="item.url">
		<menu-group [item]="item">
		</menu-group>
	</a>
</ng-template>
