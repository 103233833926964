import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CallService } from '../../../../../src/app/shared/services';
// External
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { forEach } from 'lodash';
// Helpers internal
import { AutoSubs, AutoUnsubscribe, Logger } from '../../../../../src/app/shared/misc';
// Interfaces
import { MenuStructureSplit } from '../interfaces';
// Services
import { UiParamsService } from '../services/ui-params.service';

@AutoUnsubscribe()
@Component({
	selector    : 'menu-navigation',
	templateUrl : './navigation.component.html',
	styleUrls   : ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
	@AutoSubs() subs;
	public splitStructure : MenuStructureSplit = {
		header : [],
		main   : [],
		search : null
	};
	public render_sidebar : boolean = false;
	private resize$ = new Subject<{}>();

	constructor(private call : CallService,
	            private uiParams : UiParamsService
	)
	{

		/*We have menu loaded*/
		document.querySelector('html').classList.add('menuLoaded');

		const response = JSON.parse(window['ngMenu']);

		// Sort to correct menu
		forEach(response.items, item => {
			if (item.header) {
				this.splitStructure.header = [...this.splitStructure.header, item];
			} else {
				this.splitStructure.main = [...this.splitStructure.main, item];
			}
		});

		this.render_sidebar = !!this.splitStructure.main.length;
		// We now have menu structure, check if sidebar should be leaked
		this.resize$.next({});
	}

	ngOnDestroy(): void
	{
	}

	@HostListener('window:resize', [])
	onResize()
	{
		this.resize$.next({});
	}

	ngOnInit(): void
	{
		this.subs = this.resize$.pipe(debounceTime(300)).subscribe(() => this.handleResize());
		// Set first time, then wait for resize event
		this.resize$.next({});
	}

	handleResize()
	{
		// Synced with _menu-shared-variables.scss $breakpoint-mobile
		this.uiParams.is_mobile_view = window.matchMedia('(max-width: 603px)').matches; // No support for IE9 and below
	}
}
