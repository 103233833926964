import { Component, Input, OnChanges } from '@angular/core';
// Interfaces
import { MenuGroupItem, MenuStructureSplit, MinimalMenuItem, SubmenuItem } from '../interfaces';
// Helpers
import { forEach, isNull } from 'lodash';
import * as Fuse from 'fuse.js';
// Services
import { UiParamsService } from '../services/ui-params.service';

@Component({
	selector    : 'menu-sidebar',
	templateUrl : './sidebar.component.html',
	styleUrls   : ['./sidebar.component.scss']
})
export class SidebarComponent implements OnChanges {

	@Input() public menuStructure: MenuStructureSplit;

	public menu_hover: boolean = false;
	public menu_press: boolean = false;

	private fuseOptions: Fuse.FuseOptions<MinimalMenuItem> = {
		shouldSort         : true,
		threshold          : 0.4,
		location           : 0,
		distance           : 40,
		maxPatternLength   : 16,
		minMatchCharLength : 2,
		keys               : [
			'label',
			'url'
		]
	};

	// @ts-ignore
	private fuse: Fuse<MinimalMenuItem>;

	ngOnChanges()
	{
		if (this.menuStructure.main) {
			this.setActiveMenuItem();
			const flattenedMenus: MinimalMenuItem[] = this.flattenMenuStructure(this.menuStructure);
			this.fuse = new Fuse(flattenedMenus, this.fuseOptions);
		}
	}

	constructor(public uiParams: UiParamsService)
	{
		/*We do not need "Keep menu" on devices lower than 1024px (mobile and tablet ones)*/
		if (window.outerWidth > 1024 && localStorage.getItem('menuStack') === 'true') this.menu_press = true;
	}

	public setActiveMenuItem()
	{
		let activeSet = false;
		this.menuStructure.main.map((item) => {
			/*We can have only one active item*/
			if(activeSet) return item;

			item.active = location.href === item.url;
			if (item.submenu)
				item.active = item.submenu.filter(
					(submenuItem) => location.href === submenuItem.url).length > 0;

			if(item.active) activeSet = true;
			return item;
		});
	}

	public setHover(bool: boolean)
	{
		if (!this.uiParams.is_mobile_view) this.menu_hover = bool;
	}

	public toggleMenuClickOpen(overlay_click: boolean = false)
	{
		const toggle = () => this.menu_press = !this.menu_press;

		// Is hamburger click
		if (!overlay_click) toggle();
		// Is overlay click and menu click open is true
		if (overlay_click && this.menu_press) toggle();

		this.saveMenu();
	}

	private saveMenu()
	{
		localStorage.setItem('menuStack', this.menu_press.toString());
		if (this.menu_press) document.querySelector('html').classList.add('menuStack');
		if (!this.menu_press) document.querySelector('html').classList.remove('menuStack');
	}

	private flattenMenuStructure(structure: MenuStructureSplit): MinimalMenuItem[]
	{
		let flattened: MinimalMenuItem[] = [];
		forEach([...structure.main, ...structure.header], (item: MenuGroupItem) => {
				// Add item to flattened if relevant
				if (!!item.url)
					flattened.push({label : item.label, url : item.url});
				// Add submenu items to flattened that have urls
				if (item.submenu) {
					forEach(item.submenu, (subitem: SubmenuItem) => {
						if (!!subitem.url)
							flattened.push({label : subitem.label, url : subitem.url});
					});
				}
			}
		);

		return flattened;
	}

	private searchMenu(query: string): MinimalMenuItem[]
	{
		if (!!query.length && query.length >= this.fuseOptions.minMatchCharLength) {
			return this.fuse.search(query);
		} else {
			return null;
		}
	}

	public handleSearch(event: Event)
	{
		const limitResults = result => {
			const max_results: number = 20 - 1; // 20 results in total
			if (result && result.length > 20) {
				return result.slice(0, max_results);
			}
			return result;
		};

		const query: string = (<HTMLInputElement>event.target).value;
		this.menuStructure.search = limitResults(this.searchMenu(query));
		if(!isNull(this.menuStructure.search)) {
			this.menuStructure.search = this.menuStructure.search.filter((menuItem, index, self) =>
				self.findIndex(menuItemSaved => ['label', 'url'].every(k => menuItemSaved[k] === menuItem[k])) === index
			);
		}
	}
}
