import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
// Own modules
import { MenuModule } from './menu.module';
// Own services
import { CallService, ConfigService } from '../../../../src/app/shared/services';
// Should only have the bootstrapped component here
import { NavigationComponent } from './navigation/navigation.component';

@NgModule({
	declarations : [],
	imports      : [
		BrowserModule,
		HttpClientModule,
		MenuModule
	],
	providers    : [
		ConfigService,
		CallService
	],
	bootstrap    : [
		NavigationComponent
	]
})
export class AppModule {
}
