<div class="submenu-relative-container">
	<div class="submenu-container">
		<div class="header">
			<h3 class="heading">
				{{ heading }}
			</h3>
		</div>
		<div class="body">
			<div *ngFor="let item of items"
			     class="item-container">
				<a *ngIf="item.url; else noLink" href="#" (click)="navigate(item.url); $event.preventDefault()">
					<div class="item">
						<p>{{item.label}}</p>
					</div>
				</a>
				<ng-template #noLink>
					<div class="item">
						<p>{{item.label}}</p>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
