import { HostListener } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector    : 'tooltip-bubble',
	templateUrl : './tooltip-bubble.html',
	styleUrls   : ['./tooltip-bubble.scss']
})
export class TooltipBubble implements OnInit {

	private bubble_key : string;
	private bubble_timeout = 3000;

	public active = false;
	@Input() public message : string;

	@HostListener('document:click', ['$event'])
	documentClick(event : MouseEvent)
	{
		this.active = false;
	}

	ngOnInit()
	{
		this.bubble_key = `tooltip-${btoa(this.message)}`;
	}

	ngAfterViewInit() : void
	{
		this.showBubble();
	};

	showBubble()
	{
		if (localStorage.getItem(this.bubble_key) === '1') return;

		setTimeout(() => {
			this.active = true;
			localStorage.setItem(this.bubble_key, '1');
		}, this.bubble_timeout);
	}

}
