import { Component, Input } from '@angular/core';
import { SubmenuItem } from '../interfaces';

@Component({
	selector    : 'menu-header-submenu',
	templateUrl : './header-submenu.component.html',
	styleUrls   : ['./header-submenu.component.scss']
})
export class HeaderSubmenuComponent {

	@Input() public heading : string;
	@Input() public items : SubmenuItem[];

	// Needed as /app/ ui router blocks ? query changes for some reason
	public navigate(href: string)
	{
		window.location.href = href;
	}

}
