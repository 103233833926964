<header [class.no_sub_menu]="!mainStructure.length">
	<menu-header-logo [mainStructure]="mainStructure" [headerStructure]="headerStructure" class="logo-container">

	</menu-header-logo>

	<div class="setup-name" *ngIf="!!mainStructure.length">
		<span>{{ setup_name }}</span>
		<spotlight *ngIf="!!mainStructure.length"></spotlight>
		<tooltip-bubble [message]="('Try out new search!' | tt)"></tooltip-bubble>
	</div>

	<ng-container *ngIf="!uiParams.is_mobile_view">
		<menu-header-submenus [headerStructure]="headerStructure">
		</menu-header-submenus>
	</ng-container>
</header>
