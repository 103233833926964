<nav
     [attr.data-press-active]="menu_press"
     [attr.data-hover-active]="menu_hover">

	<menu-hamburger (click)="toggleMenuClickOpen()"
	                [menu_press_open]="menu_press"
	                [menu_hover_open]="menu_hover">
	</menu-hamburger>

	<!-- Main menu -->
	<ng-container *ngIf="!menuStructure.search">
		<div class="menu-items-container mt-3">
			<menu-group-wrap *ngFor="let groupItem of menuStructure.main"
			                 class="menu-group-wrap"
			                 [item]="groupItem">
				<!-- .group-wrap needed for :nth-* selector in scss -->
			</menu-group-wrap>
		</div>
	</ng-container>

	<!-- Search results in first tab -->
	<ng-container *ngIf="!!menuStructure.search">
		<div class="menu-items-container search-items-container">
			<menu-group-wrap *ngFor="let groupItem of menuStructure.search"
			                 class="menu-group-wrap"
			                 [item]="groupItem">
				<!-- .group-wrap needed for :nth-* selector in scss -->
			</menu-group-wrap>
		</div>
	</ng-container>

	<!-- Header action menus are in side menu for mobile -->
	<div *ngIf="uiParams.is_mobile_view"
	     class="actions-footer">
		<menu-header-submenus [headerStructure]="menuStructure.header">
		</menu-header-submenus>
	</div>

</nav>
