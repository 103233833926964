import { Component, Input, OnChanges } from '@angular/core';
// Helpers
import { forEach } from 'lodash';
// Interfaces
import { MenuGroupItem } from '../interfaces';

@Component({
	selector    : 'menu-header-submenus',
	templateUrl : './header-submenus.component.html',
	styleUrls   : ['./header-submenus.component.scss']
})
export class HeaderSubmenusComponent implements OnChanges {

	@Input() public headerStructure : MenuGroupItem[];

	public language : MenuGroupItem;
	public help : MenuGroupItem;
	public account : MenuGroupItem;
	public logoutLink : MenuGroupItem;
	public webShop : { item_name : string, url : string } = JSON.parse(window['ngWebshop']);
	public webLinks : [{ name : string, url : string, css_class : string }] = window['ngWeblinks'];
	public userName: string;
	public webLinksActive = false;

	public current_language_shortname : string;

	constructor()
	{
		this.userName = window['ngUserName'] ?? this.account.name
	}

	ngOnChanges()
	{
		forEach(this.headerStructure, group => {
				group.hover = false;
				// Sort array to correct variables
				const item_name = group.item_name;
				if (item_name === 'languages') this.processLanguageGroup(group);
				else if (item_name === 'help') this.processHelpGroup(group);
				else if (item_name === 'user_account') this.account = group;
				else if (item_name === 'logout') this.logoutLink = group;
			}
		);
	}

	private processLanguageGroup(group : MenuGroupItem) : void
	{
		const queryStringUrlReplacement = (url, param, value) => {
			const re = new RegExp('[\\?&]' + param + '=([^&#]*)');
			const match = re.exec(url);
			let delimiter;
			let updated;
			if (!match) { // Append new parameter
				const hasQuestionMark = /\?/.test(url);
				delimiter = hasQuestionMark ? '&' : '?';
				updated = url + delimiter + param + '=' + value;
			} else {
				delimiter = match[0].charAt(0);
				updated = url.replace(re, delimiter + param + '=' + value);
			}
			return updated;
		};

		// Urls for languages are just ?lang=*, we will change to absolute
		const href : string = window.location.href;
		forEach(group.submenu, item =>
			item.url = queryStringUrlReplacement(href, 'lang', item.url.split('=')[1])
		);
		this.language = group;
		// Get the shortname of the active language
		this.current_language_shortname = group.submenu.find(
			item => item.active
		).shortname;
	}

	private processHelpGroup(group : MenuGroupItem) : void
	{
		// Some dev environments have ? instead of having hash
		forEach(group.submenu, item => {
			if (item.label === '?') item.label = 'No revision';
			else if (item.label === '? pr') item.label = 'No revision date';
		});
		this.help = group;
	}

}
